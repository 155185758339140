
.main-dash {
    gap: 32px;
    padding: 24px;
    height: auto;
    position: relative;
    background: #f5f7fa;
}
.code-box {
    background-color: #2d2d2d;
    padding: 16px;
    border-radius: 8px;
    margin-top: 8px;
    overflow-x: auto;
}
.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    border-radius: 24px;
}

.upload-container h2 {
    margin-bottom: 20px;
}
.response-content {
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-x: auto;
    background: #1e1e1e;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    color: white;
}
.response-content code {
    color: #dcdcdc;
}
.response-content pre {
    margin: 0;
}
.upload-container input {
    margin-bottom: 10px;
}
.upload-container button {
    background-color: #5FA3E2;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.upload-container button:hover {
    background-color: #4274C0;
}

.upload-container p {
    margin-top: 20px;
    color: #39465f;
}
.dash-nav {
    width: 50px;
    height: calc(100vh - 100px);
    background: linear-gradient(0deg, #5FA3E2 80.26%, rgba(146, 95, 226, 0) 143.39%, #E2D4F7 143.39%);
    border-radius: 24px;
    padding: 30px 10px;
    align-items: center;
    position: sticky;
    top: 24px;
}
.dash-nav .sidebar-icon {
    margin-left: unset;
    align-self:unset;
    margin-bottom: 18px;
    cursor: pointer;
}
.dash-nav.active .sidebar-icon {
    align-self: baseline;
    margin-left: 29px;
    margin-bottom: 14px;
    img{
        width: 26px;
    }
}
.dash-nav.active {
    width: 233px;
    height: calc(100vh - 100px);
    background: linear-gradient(0deg, #5FA3E2 80.26%, rgba(146, 95, 226, 0) 143.39%, #E2D4F7 143.39%);
    border-radius: 24px;
    padding: 30px;
    align-items: center;
    position: sticky;
    top:24px;
}
.comp-loading {
    opacity: 0.4;
}
.dash-nav .student-mask img {
    width: 25px;
    height: 25px;
}
.dash-nav.active .student-mask img {
    height: 82px;
    width: unset;
    margin: 15px 0;
}
.dash-nav.active .student-mask {
    display: flex;
    width: 130px;
    height: 130px;
}
.dash-nav .student-mask {
    display: flex;
    width: 40px;
    height: 40px;
    background: linear-gradient(180deg, #5FB3E2 0%, #4274C0 100%);
    border-radius: 32px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.dash-nav .nav-list {
    gap: 32px;
}
.dash-nav .nav-list .nav-link {
    gap: 12px;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}
.dash-nav .nav-list .nav-link .icon {
    width: 30px;
    height: 30px;
    opacity: 0.6;
}
.dash-nav.active .nav-list .nav-link span {
    display: unset;
}
.dash-nav .nav-list .nav-link span {
    display: none;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    opacity: 0.6;
}
.dash-nav .nav-list .nav-link:hover {
    .icon {
        opacity: 1;
    }
    span {
        opacity: 1;
    }
}
.dash-nav .nav-list .nav-link.active {
    .icon {
        opacity: 1;
    }
    span {
        opacity: 1;
    }
}
.main-dash {
    .content {
        width: 100%;
        gap: 30px;
        .user-dash {
            height: 256px;
            min-height: 256px;
            background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
            box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
            border-radius: 24px;
            overflow: hidden;
        }
        .user-dash {
            position: relative;
            .date {
                margin: 48px auto auto 59px;
            }
            .greeting {
                padding-bottom: 48px;
            }
            .date, .greeting span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.75);
            }
            .greeting span {
                margin: 1px auto auto 59px;
            }
            .greeting .g-head {
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 48px;
                color: #FFFFFF;
                margin: 0 0 0 59px;
            }
            .user-col {
                .bgImg {
                    width: 507px;
                    height: 354.58px;
                }
            }
        }
        .row-dash{
            gap:30px;
        }
        .col-dash{
            box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
            background: #fff;
            width: 100%;
            --bs-card-border-width: 1px;
            --bs-card-border-color: #dbe0e5;
            --bs-card-border-radius: 22px;
            --bs-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
            --bs-card-bg: #ffffff;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            color: #39465f;
            word-wrap: break-word;
            background-color: var(--bs-card-bg);
            background-clip: border-box;
            border: var(--bs-card-border-width) solid var(--bs-card-border-color);
            border-radius: var(--bs-card-border-radius);

            .top-head {
                border-bottom: 1px solid #dbe0e5;
                padding: 1rem 1rem 1rem 1.6rem;
                span {
                    font-size: 0.975rem;
                    font-weight: 600;
                }
            }
        }
        .col-dash .docslist {
            padding: 16px;
            ul {
                margin: 0 0 0 0;
                padding-left: 0px;
                li {
                    background: #f1f1f1;
                    font-size: 14px;
                    color:#39465f;
                    span{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 80%;
                    }
                    div.delete img {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .height-divider {
            background: #dbe0e5;
            height: 100%;
            width: 1px;
        }
        .docsupload {
            color: #39465f;
            text-decoration: none;
        }
        .col-dash .docs-row .docsupload {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            width: 46%;
            color: #39465f;
            text-decoration: none;
            .docslink {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #39465f;
                text-decoration: none;
            }
            .docsIcon {
                width: 80.50000000000009px;
                margin-bottom: 10px;
                height: auto;
            }
            span{
                text-align: center;
                font-size: 14px;
                color: #39465f;
                text-decoration: none;

            }
        }
        .col-dash .docsupload:hover {
            scale:1.05;
            transition: all 0.5s;
        }
        .col-dash .interview-row {
            .docsupload {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 33.3%;
                min-height: 207px;
                height: fit-content;
                .docsIcon {
                    width: 60.50000000000009px;
                    margin-bottom: 10px;
                    height: auto;
                }
                span{
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
    }
}

.main-dash .content .user-dash .profile-button {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 44px;
    width: 44px;
    border-radius: 100px;
    display: flex;
    background: #ffffffba;
    align-content: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .profile-icon {
        width: 38px;
        height: 38px;
    }
}

.main-dash {
    .mock {
        .row-dash {
            height: 100%;
        }
        .row-dash.active .col-dash:nth-child(2) {
            max-width: 100%;
        }
        .col-dash {
            height: 100%;
            flex: 1 1;
            .top-head {
                padding: 0.8rem 0.8rem 0.8rem 1.2rem;
            }
            .chats {
                padding: 0.8rem;
                gap: 16px;
                height: 100%;
                flex-flow: column;
                display: flex;
                overflow: auto;
                height: inherit;
            }
            .chats .chat-box.other {
                border-radius: 16px 16px 16px 0px;
                background: #d6d6d65e;
                border: 0.6px solid rgba(170, 170, 170, 0.253);
                margin-right: 15px;
            }
            .chats .chat-box.user:first-child {
                margin-top: auto;
            }
            .chats .chat-box.user {
                border-radius: 16px 16px 0px 16px;
                background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
                box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
                border: 0.6px solid rgba(170, 170, 170, 0.253);
                margin-left: 15px;
            }
            .chats .chat-box.highlight:first-child {
                margin-top: auto;
            }
            .chats .chat-box.highlight {
                border-radius: 16px 16px 16px 16px;
                background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
                box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
                border: 0.6px solid rgba(170, 170, 170, 0.253);
                margin-left: unset;
            }
            .chats .chat-box {
                padding: 0.7rem 0.8rem;
                gap: 5px;
                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.3;
                }
                .chats-info {
                    gap: 10px;
                    span {
                        font-size: 12px;    
                        font-weight: 400;
                        position: relative;
                        line-height: 1.3;
                    }
                    span:nth-child(2n-1)::after {
                        content: "";
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        border-radius: 100px;
                        background: rgb(100, 100, 100);
                        right: -7px;
                        top: 7.3px;
                    }
                }
            }
            .chat-inputs {
                padding: 0.8rem;
                gap: 12px;
                input {
                    border-radius: 10px;
                    padding: 0.4rem 0.8rem;
                    width: 100%;
                    border-color: rgba(0, 0, 0,0.15);
                }
                input:focus-visible {
                    outline: none;
                }
                button {
                    border: none;
                    background: #eeecec;
                    img {
                        width: 80%;
                        margin-top: 4px;
                    }
                    img.active {
                        animation-name: voiceanim;
                        animation-duration: 1s;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                    }
                }
            }
        }
        .col-dash:nth-child(1){
            max-width: 17%;
            height: calc(100vh - 45px);
        }
        .col-dash:nth-child(2){
            max-width: 70%;
            height: calc(100vh - 45px); 
        }
        .col-dash:nth-child(3){
            max-width: 13%;
            height: calc(100vh - 45px);
        }
        .highlights {
            display: none;
        }
        .highlights.active {
            display: block;
        }
        .highlightOpen button img {
            width: 50px;
        }
        .highlightOpen button {
            background: transparent;
            border: none;
            width: 50px;
        }
        .highlightOpen {
            display: block;
            position: fixed;
            right: 9px;
            top: 40%;
        }
    }
}
.dash-nav.active + .content.mock .row-dash .col-dash:nth-child(1){
    max-width: 20%;
    height: calc(100vh - 45px);
}
.dash-nav.active + .content.mock .row-dash .col-dash:nth-child(2){
    max-width: 65%;
    height: calc(100vh - 45px); 
}
.dash-nav.active + .content.mock .row-dash.active .col-dash:nth-child(2){
    max-width: 100%;
    height: calc(100vh - 45px); 
}
.dash-nav.active + .content.mock .row-dash .col-dash:nth-child(3){
    max-width: 15%;
    height: calc(100vh - 45px);
}


@keyframes voiceanim {
    0% {
        opacity: 0.8;
        scale: 1;
        filter: contrast(1);
    }
    50% {
        opacity: 1;
        scale: 1.2;
        filter: contrast(2);
    }
    100% {
        opacity: 0.8;
        scale: 1;
        filter: contrast(1);
    }
}

.modal-resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #0000002f;
    -webkit-backdrop-filter:blur(3px) ;
    backdrop-filter: blur(3px);
    transition: all 0.7s linear;
    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 32px;
        cursor: pointer;
        color: #fff;
        transition: all 0.3s ease;
        transform: rotate(45deg);
    }
    .inner {
        padding: 30px;
        background:#ffff;
        border-radius: 20px;
        min-width: 470px;
        width: 470px;
        p {
            color: #4772b1;
            text-align: center;
            margin-bottom: 0;
        }
        .upload-col.col-flex{
            
            h3{
                font-size: 18px;
                text-align: center;
                margin-bottom: 4px;
                font-weight: 600;
                color: #333;
            }
            .upload-outer .upload-file-box {
                border: 1px dashed;
                border-color: #667fb87d;
                border-radius: 14px;
                background: #667fb81f;
                height: 132px;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            button {
                background: #5F93E2;
                border: none;
                padding: 10px 10px;
                margin-top: 10px;
                border-radius: 15px;
                color: #fff;
                font-size: 15px;
                letter-spacing: 0.2px;
                font-weight: 500;
                transition: all 0.7s;
                cursor: pointer;
            }
            button:hover {
                background: linear-gradient(98.5deg, #4a78bd 53.12%, #CFE4F7 155.43%);
            }
        } 
    }

}
.modal-resume .inner .uploaded-files ul li {
    padding: 20px 15px;
    justify-content: center;
    background: #dae6ff8a;
    span {
        font-size: 16px;
        text-align: center;
    }
}
.main-dash
.content
.col-dash .docslist {
    overflow-y: auto;
    max-height: 175px;
    max-width: 280px;
    min-height: 175px;
}
.col-dash .docslist::-webkit-scrollbar {
    width: 0.4em;
    height: 0.3em;
    border-radius: 10px;
    padding: 2px;
}
.main-dash
.content
.col-dash .docslist::-webkit-scrollbar-thumb {
    background-color: #608eca;
    outline: 1px solid rgb(112, 161, 209);
    border-radius: 10px;
    padding: 2px;
}
.main-dash
.content
.col-dash .docslist::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(64, 164, 211, 0.3);
    padding: 2px;
}

.modal-voice .inner .upload-col {
    button {
        opacity: 0.5;
    }
    button.active {
        opacity: 1;
    }
    .voice-cont {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background: #ebebeb;
        margin: 10px auto;
        border-radius: 16px;
        min-width: 270px;
        .rec-start.active, .rec-voice.active, .rec-stop.active {
            display: block;
            cursor: pointer;
        }
        .rec-start.active {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .rec-start img, .rec-stop img {
            width: 70px;
        }
        .rec-stop.active img {
            width: 60px;
        }
        .rec-stop.active {
            background: #fff;
            border-radius: 14px;
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .rec-voice.active {
            background: rgba(7, 83, 207, 0.494);
            border-radius: 14px;
            padding: 7px 13px 0px 13px;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .rec-voice img {
            width: 100px;
        }
        .rec-start, .rec-voice, .rec-stop {
            display: none;
            cursor: pointer;
        }
    }
}

.f-screenpoppup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(5px);
    justify-content: center;
    align-content: center;
    align-items: center;
    animation: 0.5s ease-out 0s 1 popupbg;
    z-index: 10;
}

.f-screenpoppup .popup-cont {
    background: linear-gradient(98.5deg, #e4efff99 53.12%, #aebac5 155.43%);
    border-radius: 16px;
    padding: 30px 30px;
    color: #242424;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    top: 0%;
    animation: 1s ease-out 0s 1 popupcont;
    left: 0%;
    width: 560px;
    transform: translate(0%, 0%);
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 24px;
        height: 35px;
        width: 35px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        rotate: 45deg;
        cursor: pointer;
        background: #fff;
        animation: 2s ease-out 0s 1 popupclose;
        border-radius: 100px;

    }
    h3{
        font-size: 20px;
        font-weight: 500;
        text-align: left;
    }
    .reminder {
        margin-bottom: 16px;
        color: #e70000;
    }
    .profile-button {
        margin: -5px auto 0 0;
        padding: 7px 20px;
        background: #5f93e2;
        width: fit-content;
        color: #fff;
        border-radius: 19px;
        cursor: pointer;
        font-size: 18px;
    }
    .button-row {
        gap: 16px;
        .profile-button-session {
            margin-right: -5px 0 0 0 !important;
            padding: 7px 20px;
            background: #5f93e2;
            width: fit-content;
            color: #fff;
            border-radius: 19px;
            cursor: pointer;
            font-size: 18px;
        }
    }
}
@keyframes popupbg {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(5px);
    }
}
@keyframes popupcont {
    0% {
        top: -100%;
    }
    100% {
        top: 0%;
    }
}
@keyframes popupclose {
    0% {
        rotate: 0deg;
    }
    50% {
        rotate: 0deg;
    }
    100% {
        rotate: 45deg;
    }
}

@media (min-width:1180px) {
    .main-dash .content .user-dash {
        height: 256px;
        min-height: 256px;
        background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
        box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        overflow: hidden;
    }
    .main-dash
    .content
    .user-dash
    .date, .greeting span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.75);
    }
    .main-dash
    .content
    .user-dash
    .date {
        margin: 48px auto auto 59px;
    }
    main-dash
    .content
    .user-dash
    .greeting span {
        margin: 1px auto auto 59px;
    }
    .main-dash .content .user-dash .user-col img.bgImg {
            width: 507px;
            height: 354.58px;
    }
}
@media (max-width:1179px) {
    .main-dash
    .content
    .user-dash
    .user-col
    .bgImg {
        width: 395px;
        height: 354.58px;
    }
    .main-dash
    .content
    .user-dash
    .date, .main-dash
    .content
    .user-dash .greeting span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.75);
    }
    .main-dash
    .content
    .user-dash {
        height: 199px;
        min-height: 195px;
        background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
        box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        overflow: hidden;
    }
    .main-dash
    .content
    .user-dash
    .date {
        margin: 28px auto auto 39px;
    }
    .main-dash
    .content
    .user-dash
    .greeting .g-head {
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 48px;
        color: #FFFFFF;
        margin: 0 0 0 39px;
    }
    .main-dash
    .content
    .user-dash
    .greeting span {
        margin: 1px auto auto 39px;
    }
    .main-dash
    .content
    .user-dash
    .greeting {
        padding-bottom: 28px;
    }
}

.uploading-stages{
    background: #74dd1f;
    border: none;
    color: #fff !important;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 15px;
}

.myresumes {
    gap: 0px;
    .head {
        font-size: 16px;
        color: #39465f;
        font-weight: 400;
        text-align: center;
        margin: 5px 0 18px 0;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        flex-wrap: wrap;
        gap: 8px;
        
        .file.active {
            background: #6795ff54;
            box-shadow: 0px 0px 11px 1px #5d7eaf80;
        }
        .file:hover {
            background: #51689f40;
        }
        .file {
            gap: 8px;
            max-width: calc(100% - 10px);
            width: calc(235px - 20px);
            background: #afaeae2b;
            padding: 12px 8px;
            border-radius: 10px;
            cursor: pointer;
            align-items: center;
            img.thumb {
                width:50px;
            }
            span {
                font-size: 14px;
                font-weight: 400;
                color:#39465f;
                text-wrap: wrap;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }
        }
        .facade {
            padding: 5px;
            background: #f5f5f5;
        }
    }
}

.chatpopup-trigger {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 10;
    background: transparent;
    img {
        width: 50px;
        height: 50px;
        box-shadow: 0px 4px 20px rgb(84 84 84 / 32%);
        border-radius: 72px;
        border-bottom-right-radius: 30px;
    }
    .chatPopup {
        border-radius: 22px;
        position: absolute;
        border-bottom-right-radius: 5px;
        right: 73px;
        bottom: 0;
        
        .loadmessage {
            min-width: 200px;
            color:#4772b1;
            padding: 10px 23px;
            animation-name: chat-animation;
            animation-duration: 1.5s;
            animation-timing-function: ease-in-out;
            animation-iteration-count:2;
            background: #DAE6FF;
            border-radius: 22px;
            border-bottom-right-radius: 5px;
        }
        
    }
    .chatPopup {
        min-width: 300px;
        background: #fff;
        box-shadow: 0px 4px 20px rgb(84 84 84 / 32%);
        .input {
            width: 86%;
            padding: 10px 16px;
            input {
                background: #e8e8e8;
                border: none;
                outline: none;
                margin-right: 10px;
                padding: 5px 16px;
                width: 100%;
                border-radius:6px;
            }
            button {
                background: #a4c2ed;
                color: #1333a7;
                border: none;
                padding: 5px 16px;
                border-radius: 6px;
                font-size: 15px;
            }
        }
    }
    .chatPopup .show-chats .body {
        height: 350px;
        max-height: 350px;
        overflow-y: scroll;
        overflow-x: hidden;
        gap: 16px;
        padding: 10px 16px;
        .user-chats {
            padding-left: 10px;
        }
        .other-chats {
            padding-right: 10px;
        }
        .chats {
            
            .user {
                width: 90%;
            max-width: 90%;
            margin: 0;
            font-weight: 400;
             color: #454545;
            }
            .other {
                width: 90%;
            max-width: 90%;
            margin: 0;
                font-weight: 400;
        color: #454545;
            }
            .mychats {
                background: #DAE6FF;
                padding: 5px 10px;
                border-radius: 15px;
                border-bottom-right-radius: 2px;
                font-size: 15px;
                font-weight: 400;
                color: #4772b1;
            }
            .otherchats {
                background: #DAE6FF;
                padding: 5px 10px;
                border-radius: 15px;
                border-bottom-left-radius: 2px;
                font-size: 15px;
                font-weight: 400;
                color: #4772b1;
            }
        }
    }
    .chatPopup .show-chats .top-head {
        padding: 10px 16px;
        background: #5F9BE2;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        color:#fff;
    }
}

@keyframes chat-animation {
    0% {
        background: #DAE6FF;
        display: block;
        height: 100%;
    }
    50% {
        opacity: 1;
        background: #cfd6e4;
        display: block;
        height: 100%;
    }
    100% {
        background: #DAE6FF;
        display: none;
        height: 0;
        overflow: hidden;
    }
}