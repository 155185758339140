.Jobs-tophead {
    background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
    box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
    max-height: 171px;
}
.Jobs-tophead .head-col {
    flex-direction: column;
    justify-content: center;
    padding: 17.5px 0;
}

.Jobs-tophead .head-col span {
    font-size: 36px;
    line-height: 1.3em;
    letter-spacing: 1%;
    margin: 0;
    margin-left: 24px;
    font-weight: 400;
    color: #e5e8ef;
}
.Jobs-tophead .head-col h2 {
    margin: 0;
    margin-top: 22px;
    margin-left: 24px;
    font-size: 30px;
    line-height: 1.3em;
    color: #f3f3f3;
    font-weight: 600;
}
.Jobs-tophead .img-col {
    justify-content: center;
    margin-left: auto;
    margin-right: 2%;
}
.Jobs-tophead .img-col .banner {
    width: 460px;
    max-height: 100%;
    max-height: 171px;
    object-fit: cover;
    object-position: 50% 38%;
    margin-right: 30px;
}
.job-container {
    gap: 3.1%;
    width: 100%;
}

.job-container .selection-tab {
    width: 30.1%;
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    border-radius: 24px;
    color: #fff;
    overflow: auto;
    max-height: 62vh;
}
.job-container .selection-tab::-webkit-scrollbar {
    width: 0.4em;
    height: 0.3em;
    border-radius: 10px;
    padding: 2px;
}
.job-container .selection-tab::-webkit-scrollbar-thumb {
    background-color: #608eca;
    outline: 1px solid rgb(112, 161, 209);
    border-radius: 10px;
    padding: 2px;
}
.job-container .selection-tab::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(64, 164, 211, 0.3);
    padding: 2px;
}
.job-container .selection-tab .job-card {
    padding: 18px 18px 16px 18px;
    position: relative;
    cursor: pointer;
}
.job-container .selection-tab .job-card:last-child:after {
    display: none;
}
.job-container .selection-tab .job-card::after {
    content: "";
    position: absolute;
    bottom: 1px;
    background: #5c42423a;
    height: 1px;
    width: 90%;
    border-radius: 24px;
    z-index: 0;
}
.job-container .selection-tab .job-card.active::after, .job-container .selection-tab .job-card:hover::after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 0;
    transition: all 0.5s ease;
    background: #0E60C1;
    height: 2px;
    width: 100%;
    border-radius: 24px;
    z-index: 0;
}
.job-container .selection-tab .job-card:nth-child(1).active, .job-container .selection-tab .job-card:nth-child(1) {
    border-radius: 24px 24px 0 0;
}
.job-container .selection-tab .job-card:last-child.active, .job-container .selection-tab .job-card:last-child:hover {
    border-radius: 0 0 24px 24px;
}
.job-container .selection-tab .job-card.active, .job-container .selection-tab .job-card:hover {
    background: #EBF2FF;
}
.job-container .selection-tab .job-card .top {
    margin-bottom: 1px;
    justify-content: space-between;
}
.job-container .selection-tab .job-card .top .comp-name{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #256BBD;
}
.job-container .selection-tab .job-card .top .date {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #647595;
}
.job-container .selection-tab .job-card .middle {
    margin-bottom: 1px;
}
.job-container .selection-tab .job-card .middle .position {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #183A6D;
    margin: 0;
}
.job-container .selection-tab .job-card .bottom {
    margin-bottom: 0px;
    justify-content: space-between;
}
.job-card span.location.active {
    background: #586784;
    border-radius: 5px;
    color: #fff !important;
    padding: 0 4px;
}
.job-container .selection-tab .job-card .bottom .dot {
    width: 8px;
    height: 8px;
    min-width: 8px;
    background: #58678485;
    border-radius: 40px;
    margin: 0 6px 2px 6px;
}
.job-container .selection-tab .job-card .bottom .location .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}
.job-container .selection-tab .job-card .bottom .location {
    font-size: 14px;
    color: #586784;
    line-height: 21px;
    max-width: calc(100% - 30%);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.job-container .selection-tab .job-card .bottom .posted {
    font-size: 14px;
    color: #4C83A3;
    line-height: 21px;
    text-wrap: nowrap;
}
.job-container .selection-tab .job-card .bottom span.posted.green, .job-container .description-tab .top-row .info-lhs .posted span.green {
    color: #0fb322;
}
.job-container .selection-tab .job-card .bottom span.posted.red, .job-container .description-tab .top-row .info-lhs .posted span.red {
    color: #c51313;
}
.job-container .description-tab .infopara {
    height: calc(100% - 8%);
    background: linear-gradient(120deg, #e4e7eb, #b5c6db, #bfcbd9, #90aacb, #a9c0d9, #95a7bd);
    animation-name: job-facade;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    border-radius: 18px;
    color: #1152bb;
    padding: 16px 0 0 0;
    text-align: center;
    margin: 0;
    transition: all 05s;
}

@keyframes job-facade {
    0% {
        background: #e4e7eb;
    }
    50% {
        background:  #b5c6db;
    }
    100% {
        background: #e4e7eb;
    }
}

.job-container .description-tab {
    width: 66.7%;
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    border-radius: 24px;
    padding: 30px;
    max-height: 52.5vh;
    overflow: auto;
}
.job-container .description-tab::-webkit-scrollbar {
    width: 0.4em;
    height: 0.3em;
    border-radius: 10px;
    padding: 2px;
}
.job-container .description-tab::-webkit-scrollbar-thumb {
    background-color: #608eca;
    outline: 1px solid rgb(112, 161, 209);
    border-radius: 10px;
    padding: 2px;
}
.job-container .description-tab::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(64, 164, 211, 0.3);
    padding: 2px;
}
.job-container .description-tab .top-row {
    position: relative;
    justify-content: space-between;
}
.job-container .description-tab .top-row::after {
    content: "";
    position: absolute;
    background: #F4F7FC;
    z-index: 0;
    bottom: -10px;
    background: #0000002a;
    height: 1px;
    width: 100%;
}
.job-container .description-tab .top-row .info-lhs {
    gap: 4px;
    max-width: 70%;
}
.job-container .description-tab .top-row .info-lhs .comp-name {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #256BBD;
    margin: 0;
}
.job-container .description-tab .top-row .info-lhs .job-position {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color:#183A6D;
    margin: 0;
}
.job-container .description-tab .top-row .info-lhs .location .dot {
    width: 8px;
    height: 8px;
    background: #58678485;
    border-radius: 40px;
    margin: 0 6px 2px 6px;
}
.job-container .description-tab .top-row .info-lhs .location .active {
    background: #586784;
    border-radius: 5px;
    color: #fff !important;
    padding: 0 4px;
}
.job-container .description-tab .top-row .info-lhs .location {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #586784;
    margin: 0;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
}
.job-container .description-tab .top-row .info-lhs .date {
    font-size: 15px;
    line-height:23px;
    color: #647595;
    margin: 0;
}
.job-container .description-tab .top-row .info-lhs .posted {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #828488;
    margin: 0;
}
.job-container .description-tab .top-row .info-lhs .posted span {
    color: #4C83A3;
}
.job-container .description-tab .top-row .info-rhs {
    gap: 4px;
    align-items: end;
    justify-content: space-around;
}
.job-container .description-tab .top-row .info-rhs .ai-button .ai-icon {
    width: 22px;
}
.job-container .description-tab .top-row .info-rhs .ai-button.disabled {
    animation-name:aianim;
    animation-duration:1s;
    animation-iteration-count:infinite;
    animation-timing-function:ease-in-out;
}
.job-container .description-tab .top-row .info-rhs .ai-button {
    background: linear-gradient(98.5deg, #5f93e259 53.12%, #cfe4f75f 127.29%);
    color: #2b6ac9;
    display: flex;
    align-items: center;
    border-radius: 24px;
    align-content: center;
    cursor: pointer;
    padding: 8px 30px;
    gap: 8px;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 27px;
}
@keyframes aianim {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0.5;
    }
}
.job-container .description-tab .top-row .info-rhs a{
    font-size: 18px;
    line-height: 27px;
    color:#fff;
    background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 127.29%);
    box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 8px 30px;
    cursor: pointer;
    text-decoration: none;
}
.job-container .description-tab .bot-description {
    /* height: 100%; */
    gap: 8px;
    margin-top: 18px;
}
.job-container .description-tab .bot-description .jdhead {
    font-size: 19px;
    font-weight: 600;
    color: #04254B;
    line-height: 29px;
    margin: 0;
}
.job-container .description-tab .bot-description .jshead {
    font-size: 18px;
    font-weight: 600;
    color: #04254B;
    line-height: 28px;
    margin: 0;
}
.job-container .description-tab .bot-description .jdpara {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
}
.job .filter {
    padding: 12px 40px 6px 40px;
    gap: 30px;
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    border-radius: 24px;
    margin: -8px 0 -8px 0;
}
.job .filter .nav-head::after {
    content: "";
    position: absolute;
    right: -16px;
    top: -2px;
    height: 25px;
    width: 1px;
    background: #A1C8FC;
}
.job .filter .nav-head {
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 7px;
}
.job .filter .nav-head .icon {
    width: 26px;
    height: 25px;
}
.job .filter .nav-head span {
    font-size: 16px;
    font-weight: 500;
    color: #1477F8;
}

.job .filter .f-nav::after {
    content: "";
    position: absolute;
    right: -16px;
    top: -2px;
    height: 25px;
    width: 1px;
    background: #A1C8FC;
}
.job .filter .f-nav.clear span {
    color: rgb(224, 84, 84);
}
.job .filter .f-nav {
    position: relative;
    cursor: pointer;
}
.job .filter .f-nav input:focus-visible::placeholder {
    color: #fff;
}
.job .filter .f-nav input:focus-visible {
    background: #1c71e9 !important;
    color: #fff;
    font-weight: 300;
}
.job .filter .f-nav input {
    background: #8fb6eb30;
    border: none;
    padding: 5px 0 1px 8px;
    margin: -3px -14.4px 0 -13.8px;
    outline: none !important;
    min-width: 200px;
    width: 100%;
}
.job .filter .f-nav:hover .list {
    display: flex;
    opacity: 1;
} 



.job .filter .f-nav .list::-webkit-scrollbar {
    width: 0.4em;
    height: 0.3em;
    border-radius: 10px;
    padding: 2px;
}
.job .filter .f-nav .list::-webkit-scrollbar-thumb {
    background-color: #608eca;
    outline: 1px solid rgb(112, 161, 209);
    border-radius: 10px;
    padding: 2px;
}
.job .filter .f-nav .list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(64, 164, 211, 0.3);
    padding: 2px;
}
.job .filter  .job-count {
    margin-left: auto;
}
.job .filter  .job-count span {
    font-size: 16px;
    color: #119747;
    font-weight: 600;
}
.job .filter .f-nav .list li:hover {
    color: #1477F8;
}
.job .filter .f-nav .list li {
    border-bottom: 1px solid #3333332d;
    padding: 8px 0 8px 0;
}
.job .filter .f-nav .list {
    margin: 0;
    opacity: 0;
    position: absolute;
    z-index: 1;
    /* box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12); */
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background: #f3f5f8;
    padding: 10px 12px;
    line-height: 1.3em;
    gap: 6px;
    display: none;
    text-align: center;
    width: 100%;
    left: -12px;
    list-style: none;
    max-height: 370px;
    overflow: auto;
}
.job .filter .f-nav .list li.active {
    color: #fff;
    background: #1477F8;
    border-radius: 10px;
}
.job .filter .f-nav span:hover, .job .filter .f-nav.active span {
    color: #1477F8;
}
.job .filter .f-nav span {
    font-size: 16px;
    color: #6c6c6c;
}
.job .filter .f-nav span.active-remote {
    background: #5f93e2;
    color: #f5f5f5;
    padding: 2px 5px 4px 5px;
    border-radius: 2px;
}
.job-portal .ai-head {
    gap: 12px;
}
.job-portal .ai-head .ai-icon {
    width: 30px;
    height: 30px;
}
.job-portal .ai-head {
    font-size: 20px;
    font-weight: 400;
    color: #04254B;
    padding-bottom: 9px;
}
.job-portal .inner-popup::-webkit-scrollbar {
    width: 0.4em;
    height: 0.3em;
    border-radius: 10px;
    padding: 2px;
}
.job-portal .inner-popup::-webkit-scrollbar-thumb {
    background-color: #608eca;
    outline: 1px solid rgb(112, 161, 209);
    border-radius: 10px;
    padding: 2px;
}
.job-portal .inner-popup::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(64, 164, 211, 0.3);
    padding: 2px;
}
.job-portal .inner-popup {
    max-height: 400px;
    overflow: auto;
    margin-top: 12px;
    color: #1d3d63;
}
.job-portal .inner-popup.loading .loader-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    position: relative;
    height: 100%;
}
.job-portal .inner-popup.loading .loader-icon img {
    object-fit: cover;
    width: 80px;
    height: 80px;
    opacity: 0.6;
    border-radius: 100px;
}
.job-portal .inner-popup.loading {
    height: 400px;
    width: 100%;
    animation-name: aiLoading;
    animation-duration: 1s;
    animation-iteration-count:infinite;
    animation-timing-function:ease-in-out;
    border-radius: 20px;
}
@keyframes aiLoading {
    0% {
        background: #a4bad7b6;
    }
    50% {
        background: #7fa1cdb6;
    }
    100% {
        background: #a4bad7b6;
    }
}
.job-portal h4 {
    font-size: 20px;
    font-weight: 400;
    color: #041b36;;
    margin: 0;
}
.f-screenpoppup .popup-cont .copy-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    margin-left: 16px;
    font-size: 16px;
    img {
        width: 30px;
        height: 30px;
    }
}

.myresumes h3.head {
    font-size: 18px;
    margin-top: 10px;
}