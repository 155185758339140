.content {
    .docs-tophead {
        background: linear-gradient(98.5deg, #5F93E2 53.12%, #CFE4F7 155.43%);
        box-shadow: 8px 8px 48px 8px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        overflow: hidden;
        min-height: 70px;
        max-height: 70px;
        .head-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .head-col.link .navicons {
            margin-left: 24px;
            width: 26px;
            margin-top: 4px;
            height: fit-content;
            margin-right: 11px;
        }
        .head-col.link, .head-col.link + .img-col {
            cursor:pointer
        }
        .head-col.link h2 {
            margin: 0;
            color: #f3f3f3;
            font-weight: 600;
        }
        .head-col h2 {
            margin: 0;
            color: #f3f3f3;
            font-weight: 600;
            margin-left: 24px;
        }
        .img-col {
            justify-content: center;
            margin-left: auto;
            margin-right: 2%;
            .bgImg {
                width: 215px;
            }
            .lnav{
                border: 1.5px solid #FFF;
                border-radius: 50px;
                width: 28px;
                height: 28px;
                font-weight: 500;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }
    }
}

.docs .upload-container, .modal-resume{
    gap: 8px;
    .upload-col {
        gap: 8px;
        width: 100%;
        h3 {
            font-size: 15px;
            font-weight: 500;
            color:#454545;
            margin: 0;
        }
        .upload-outer {
            position: relative;
            .upload-file-box::before {
                content: "";
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzkiIGhlaWdodD0iMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMyLjM3NSAxMi4xODhDMzEuNSA1LjY4OCAyNiAuODc0IDE5LjM3NS44NzQgMTMuNjg3Ljg3NSA4Ljc1IDQuNDM4IDYuOTM3IDkuODEzIDIuODc1IDEwLjY4NyAwIDE0LjE4NiAwIDE4LjM3NGMwIDQuNTYzIDMuNTYzIDguMzc1IDguMTI1IDguNzVoMjMuODEzYzMuODEyLS4zNzUgNi44MTItMy42MjUgNi44MTItNy41IDAtMy42ODgtMi43NS02Ljg3NS02LjM3NS03LjQzOHptLTYuMzEzIDMuNWEuNTY3LjU2NyAwIDAxLS40MzcuMTg3LjU2Ny41NjcgMCAwMS0uNDM4LS4xODhMMjAgMTAuNXYxMi4yNWMwIC4zNzUtLjI1LjYyNS0uNjI1LjYyNXMtLjYyNS0uMjUtLjYyNS0uNjI1VjEwLjVsLTUuMTg4IDUuMTg4YS42MDQuNjA0IDAgMDEtLjg3NCAwIC42MDQuNjA0IDAgMDEwLS44NzZsNi4yNS02LjI1Yy4wNjItLjA2Mi4xMjUtLjEyNC4xODctLjEyNC4xMjUtLjA2My4zMTMtLjA2My41IDAgLjA2My4wNjIuMTI1LjA2Mi4xODguMTI0bDYuMjUgNi4yNWMuMjUuMjUuMjUuNjI2IDAgLjg3NnoiLz48L3N2Zz4=);
                mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzkiIGhlaWdodD0iMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMyLjM3NSAxMi4xODhDMzEuNSA1LjY4OCAyNiAuODc0IDE5LjM3NS44NzQgMTMuNjg3Ljg3NSA4Ljc1IDQuNDM4IDYuOTM3IDkuODEzIDIuODc1IDEwLjY4NyAwIDE0LjE4NiAwIDE4LjM3NGMwIDQuNTYzIDMuNTYzIDguMzc1IDguMTI1IDguNzVoMjMuODEzYzMuODEyLS4zNzUgNi44MTItMy42MjUgNi44MTItNy41IDAtMy42ODgtMi43NS02Ljg3NS02LjM3NS03LjQzOHptLTYuMzEzIDMuNWEuNTY3LjU2NyAwIDAxLS40MzcuMTg3LjU2Ny41NjcgMCAwMS0uNDM4LS4xODhMMjAgMTAuNXYxMi4yNWMwIC4zNzUtLjI1LjYyNS0uNjI1LjYyNXMtLjYyNS0uMjUtLjYyNS0uNjI1VjEwLjVsLTUuMTg4IDUuMTg4YS42MDQuNjA0IDAgMDEtLjg3NCAwIC42MDQuNjA0IDAgMDEwLS44NzZsNi4yNS02LjI1Yy4wNjItLjA2Mi4xMjUtLjEyNC4xODctLjEyNC4xMjUtLjA2My4zMTMtLjA2My41IDAgLjA2My4wNjIuMTI1LjA2Mi4xODguMTI0bDYuMjUgNi4yNWMuMjUuMjUuMjUuNjI2IDAgLjg3NnoiLz48L3N2Zz4=);
                background-image: none;
                background-color: #b3bccd;
                width: 39px;
                height: 28px;
                margin-bottom: 10px;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                display: block;
                -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzkiIGhlaWdodD0iMjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMyLjM3NSAxMi4xODhDMzEuNSA1LjY4OCAyNiAuODc0IDE5LjM3NS44NzQgMTMuNjg3Ljg3NSA4Ljc1IDQuNDM4IDYuOTM3IDkuODEzIDIuODc1IDEwLjY4NyAwIDE0LjE4NiAwIDE4LjM3NGMwIDQuNTYzIDMuNTYzIDguMzc1IDguMTI1IDguNzVoMjMuODEzYzMuODEyLS4zNzUgNi44MTItMy42MjUgNi44MTItNy41IDAtMy42ODgtMi43NS02Ljg3NS02LjM3NS03LjQzOHptLTYuMzEzIDMuNWEuNTY3LjU2NyAwIDAxLS40MzcuMTg3LjU2Ny41NjcgMCAwMS0uNDM4LS4xODhMMjAgMTAuNXYxMi4yNWMwIC4zNzUtLjI1LjYyNS0uNjI1LjYyNXMtLjYyNS0uMjUtLjYyNS0uNjI1VjEwLjVsLTUuMTg4IDUuMTg4YS42MDQuNjA0IDAgMDEtLjg3NCAwIC42MDQuNjA0IDAgMDEwLS44NzZsNi4yNS02LjI1Yy4wNjItLjA2Mi4xMjUtLjEyNC4xODctLjEyNC4xMjUtLjA2My4zMTMtLjA2My41IDAgLjA2My4wNjIuMTI1LjA2Mi4xODguMTI0bDYuMjUgNi4yNWMuMjUuMjUuMjUuNjI2IDAgLjg3NnoiLz48L3N2Zz4=);
            }
            .upload-file-box {
                border: 1px dashed;
                border-radius: 4px;
                height: 132px;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h3{
                    font-weight: 600;
                }
                .drag-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 14px;
                    line-height: 18px;
                    margin-top: 5px;
                }
            }
            .inputContainer {
                height: 132px;
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
            }
            .fileupload-input {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                font-size: 0;
            }
        }
    }
    .input-mail:focus-visible {
        outline: none;
    }
    .input-mail {
        gap: 8px;
        width: 100%;
        .head {
            gap: 8px;
            color: #454545;
            font-size: 15px;
            font-weight: 500;
        }
        .log-in-input:focus-visible {
            outline: none;
        }
        .log-in-input {
            border-color: #b8bdc9;
            background-color: #fff;
            border-color: #b8bdc9;
            color: #2c3345;
            border-radius: 4px;
            border-width: 1px;
            border-style: solid;
            padding: 5px 5px;
        }
    }
}
.upload-container button {
    margin-top: 12px;
}
.uploaded-files {
    gap:8px;
    width: 100%;
    margin-top: 16px;
    ul{
        padding: 0;
        gap: 16px;
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        li{
            background: #dae6ff;
            padding: 10px 10px;
            border-radius:8px;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .file-name {
                color:#175ca2;
                font-weight:500;
            }
            .size{
                display: flex;
                flex-direction: row;
                gap: 3px;
                margin-right: 1%;
                span{
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .size .number {
                display: block;
            }
        }
    }
}
.uploadedFiles {
    width: 100%;
    h1{
        font-size: 16px;
        font-weight: 500;
    }
    ul{
        padding: 0;
        margin: 0;
        gap: 16px;
        list-style: none;
    }
    li{
        background: #dae6ff;
            padding: 10px 10px;
            font-size: 14px;
            border-radius:8px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color:#175ca2;
            font-weight:500;
            justify-content: space-between;
            align-items: center;
            div.delete {
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                cursor: pointer;
            }
            div.delete img{
                width: 20px;
                height: 20px;
            }
    }
}
