.profile-container {
    border-radius: 24px;
    padding: 25px;
    background: #fff;
    box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.12);
    .prof-thumb {
        gap: 24px;
        padding-left:0 0 0 15px;
        .thumb {
            width: 80px;
            height: 80px;
            border-radius: 80px;
            box-shadow: 0px 1px 15px #00000038;
            position: relative;
            input {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                font-size: 0;
            }
            .up-icon {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                cursor: pointer;
                border-radius: 500px;
                background: #00000031;
                
                img {
                    width: 38px; 
                    height: 38px;
                    position: absolute;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .thumb-img {
            width: 80px;
            height: 80px;
            border-radius: 80px;
            object-fit: cover;
            margin-bottom: 0px;
        }
        .prof-info {
            justify-content: center;
        }
        h3 {
            margin: 0;
            font-size: 16px;
            color: #454545;
            font-weight: 600;
        }
        p{
            margin: 0;
        }
    }
}
.main-dash
.content
.user-dash
.greeting .g-head {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 48px;
    color: #FFFFFF;
    margin: 0 0 0 59px;
}
.main-dash
.content
.user-dash
.greeting span {
    margin: 1px auto auto 59px;
}
.main-dash .content .user-dash {
    width: calc(100%);
    margin-left: 0;
}
.profile-inputs {
    gap: 32px;
    padding-top: 30px;
    padding-bottom: 30px;
}
.profile-inputs .profile-edit {
    width: 35%;
}
.profile-inputs .input {
    gap: 8px;
    margin-top: 8px;
}
.profile-inputs .input .head .mandatory {
    color: red;
}
.profile-inputs .input .head {
    font-size: 12px;
    line-height: 1.5em;
    color: #153060;
}
.profile-inputs .input input.ctcode {
    width: 30%;
    margin-right: 16px;
}
.profile-inputs .input input,
.profile-inputs .input select {
    color: #828282;
    background: #f1f1f1;
    padding: 10px 5px 10px 5px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    -webkit-appearance: textarea;
}
.profile-inputs .login-button:hover {
    background: transparent;
    color: #1E4178;
    font-weight: 600;
}

.profile-inputs .login-button {
    width: 100%;
    padding: 10px 10px;
    background: #1E4178;
    text-align: center;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    letter-spacing: 0.4px;
    position: relative;
    border: none;
    margin-top: 20px;
    cursor: pointer;
}

.profile-inputs .login-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s;
}

.profile-inputs .login-button:hover::before {
    opacity: 0;
    transform: scale(0.5, 0.5);
}

.profile-inputs .login-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    z-index: 1;
    border-radius: 5px;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid #1e4178c9;
    transform: scale(1.2, 1.2);
}

.profile-inputs .login-button:hover::after {
    opacity: 1;
    transform: scale(1, 1);
}